<template>
    <div class="login-box">
        <h3>用户登录</h3>
        <el-form
            class="login-form"
            status-icon
            :rules="loginRules"
            ref="loginForm"
            :model="loginForm"
            label-width="0"
        >
            <el-row>
                <el-col :span="24">
                    <el-form-item prop="username">
                        <el-input
                            size="small"
                            v-model="loginForm.username"
                            auto-complete="off"
                            placeholder="请输入用户名"
                        >
                            <i
                                slot="prefix"
                                class="el-input__icon iconfont icon-cp-usercenter"
                            ></i>
                        </el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24">
                    <el-form-item prop="password">
                        <el-input
                            size="small"
                            :type="passwordType"
                            v-model="loginForm.password"
                            auto-complete="off"
                            placeholder="请输入密码"
                            @keyup.enter.native="handleLogin"
                        >
                            <i
                                class="el-input__icon"
                                :class="
                                    passwordType == 'password'
                                        ? 'iconfont icon-cp-visable'
                                        : 'iconfont icon-cp-not-visable'
                                "
                                slot="suffix"
                                @click="showPassword"
                            ></i>
                            <i
                                slot="prefix"
                                class="el-input__icon iconfont icon-cp-password"
                            ></i>
                        </el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="14" class="remember_password">
                    <el-checkbox v-model="rememberPassword"
                        >记住密码</el-checkbox
                    >
                </el-col>
                <el-col :span="10" class="forget_password">
                    <span @click="redirectRestPassword">忘记密码</span>
                </el-col>
            </el-row>
            <el-form-item>
                <el-button
                    type="primary"
                    size="small"
                    class="login-submit"
                    @click.native.prevent="handleLogin"
                    :loading="isLoggingIn"
                    >登录</el-button
                >
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
    name: "UserLogin",
    computed: {
        ...mapGetters(["tagWel", "rememberUser", "isRememberPassword"])
    },
    data() {
        return {
            isLoggingIn: false,
            passwordType: "password",
            rememberPassword: false,
            loginForm: {
                username: "",
                password: ""
            },
            loginRules: {
                username: [
                    { required: true, message: "请输入用户名", trigger: "blur" }
                ],
                password: [
                    { required: true, message: "请输入密码", trigger: "blur" }
                ]
            }
        };
    },
    methods: {
        showPassword() {
            this.passwordType == ""
                ? (this.passwordType = "password")
                : (this.passwordType = "");
        },
        redirectRestPassword() {
            this.$router.push("/reset");
        },
        handleLogin() {
            this.$refs.loginForm.validate(valid => {
                if (valid) {
                    this.isLoggingIn = true;
                    this.$store.dispatch(
                        "rememeberLoginInfo",
                        this.rememberPassword ? this.loginForm : undefined
                    );
                    this.$store
                        .dispatch("LoginByUsername", this.loginForm)
                        .then(
                            () => {
                                this.$store.commit("ADD_TAG", this.tagWel);
                                this.$router.push({ path: this.tagWel.value });
                            },
                            error => {
                                this.$notify({
                                    title: "登录失败",
                                    message:
                                        typeof error === "string"
                                            ? error
                                            : error.message,
                                    type: "warning"
                                });
                            }
                        )
                        .finally(() => (this.isLoggingIn = false));
                }
            });
        }
    },
    mounted() {
        this.rememberPassword = this.isRememberPassword;
        this.loginForm = this.rememberUser;
    }
};
</script>
<style lang="scss">
.login-box {
    // background-image: url("../../assets/images/background/login-from.png");
    background-color: rgba($color: #fff, $alpha: 0.5);
    box-sizing: border-box;
    border-radius: 20px;
    padding: 20px;
    .login-form {
        .remember_password,
        .forget_password {
            font-size: 14px;
            cursor: pointer;
            margin-bottom: 4px;
            &:hover {
                color: #3a8ee6;
            }
        }
        .remember_password {
            text-align: left;
        }
        .forget_password {
            text-align: right;
            user-select: none;
        }
        .login-submit {
            width: 100%;
        }
    }
}
</style>
