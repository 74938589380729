<template>
    <div class="login-container">
        <h1 class="title"></h1>
        <div class="login-main">
            <h2 class="login-title"></h2>
            <UserLogin />
        </div>
    </div>
</template>
<script>
import UserLogin from "./userLogin";

export default {
    name: "Login",
    components: {
        UserLogin
    }
};
</script>
<style lang="scss">
.login-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../../assets/images/background/login.jpeg");
    background-size: 100% 100%;
    position: relative;
    height: 100vh;
    .title,
    .login-main {
        position: absolute;
        margin: 0;
        color: #555;
    }
    .title {
        position: absolute;
        top: 0;
        left: 0;
        padding: 20px;
        letter-spacing: 1px;
    }
    .login-main {
        left: 59%;
        width: 250px;
        .login-title {
            margin: 0;
            text-align: left;
            letter-spacing: 1px;
            margin-bottom: 20px;
        }
    }
}
</style>
